<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">filtre recette contravention</h4>
          <div class="row justify-content-between">

            <div class="row col-md-12">
              <div class="form-group col-md-4 mt-3 mb-0">
                <datepicker  class="form-control" v-model="from_date" inputFormat="yyyy-MM-dd"></datepicker>
              </div>

              <div class="form-group col-md-4 mt-3 mb-0">
                <datepicker class="form-control" v-model="to_date" inputFormat="yyyy-MM-dd"></datepicker>
              </div>

              <div class="mt-3 col-md-4 mb-0">
                <button @click="filterrecette()" class="btn btn-info">
                  <i class="bx bx-slider align-middle me-1"></i> Lancer le filtre
                </button>
              </div>
            </div>
          </div>
          </div>
        <div class="table-responsive mt-3" v-if="!loading">
          <table class="table table-nowrap table-centered mb-0 align-middle">
            <thead class="table-light">
            <tr>
              <th scope="col">Titre</th>
              <th scope="col">Quantite</th>
              <th scope="col">Montant</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="data in contrastat" :key=data.id>
              <td>{{ data.type_contravention }}</td>
              <td>{{ data.quantite }}</td>
              <td>{{ data.montant }}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{{quantite_totale}}</td>
              <td>{{somme_totale}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Datepicker from "vue3-datepicker";
import axios from "axios";
import { ref } from 'vue'

// gets date formatted as yyyy-MM-dd


export default {
  components: {
    Layout,
    PageHeader,
    Datepicker
  },
  name: "StatContravention",
  data() {
    return {
      from_date: ref(new Date()),
      to_date: ref(new Date()),
      datedebut: "",
      datefin: "",
      quantite_totale: 0,
      somme_totale:0,
      loading: false,
      title: "Recette contravention",
      contrastat:[],
      authError: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Statistique contravention",
          active: true,
        },
      ],
    };
  },
  methods:{
    formatDate(date) {
      const tzOffset = date.getTimezoneOffset() * 60 * 1000
      return new Date(date - tzOffset).toISOString().split('T')[0]
    },
    async filterrecette() {
      const that = this;
      this.loading = true;
      const formData = new FormData();
      formData.append('datedebut', this.formatDate(that.from_date));
      formData.append('datefin', this.formatDate(that.to_date));
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/contravention/recette-contavention/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        that.contrastat = response.data.result;
        that.somme_totale = response.data.somme_totale;
        that.quantite_totale = response.data.quantite_totale;
        that.loading = false;
      } catch (error) {
        that.authError = error;
        that.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>